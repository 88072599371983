import React from 'react';

import CheatSheet from '../components/CheatSheet/CheatSheet';

function SaberConocer() {
  return (
    <CheatSheet title='Saber and Conocer'>
      <p>Coming soon.</p>
    </CheatSheet>
  );
}

export default SaberConocer;
