import React from 'react';

import CheatSheet from '../components/CheatSheet/CheatSheet';

function Possessive() {
  return (
    <CheatSheet title='Possessive'>
      <p>Coming soon.</p>
    </CheatSheet>
  );
}

export default Possessive;
