import React from 'react';

import CheatSheet from '../components/CheatSheet/CheatSheet';

function SerEstar() {
  return (
    <CheatSheet title='Ser and Estar'>
      <p>Coming soon.</p>
    </CheatSheet>
  );
}

export default SerEstar;
